import React from "react";
const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="text-center">
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nbaquizzes.com/"
            className="logo"
          >
            <span> Brought to you by </span>
            <br />
            {/* <h4> NBA Quizzes.com </h4> */}
            <img
              src="https://nbaquizzes.com/wp-content/uploads/2021/01/NBA-Quiz-Logo.png"
              height={24}
              style={{ marginTop: "8px" }}
              alt="logo"
            />
            <br />
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
